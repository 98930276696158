import React from 'react'

import { Container } from 'semantic-ui-react'
import MainSlider from '../components/MainSlider/MainSlider'
import Layout from '../components/Layouts/default'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container fluid>
          <MainSlider />
        </Container>
      </Layout>
    )
  }
}

export default IndexPage
