import React from 'react'
import Carousel from 'nuka-carousel'
import Link from 'gatsby-link'

import './MainSlider.scss'

import { StaticImage } from 'gatsby-plugin-image'

function MainSlider() {
  const settings = {
    className: 'main-slider',
    autoplay: false,
    autoplayInterval: 3000,
    slidesToShow: 1,
    wrapAround: true,
    adaptiveHeight: false,
    // https://formidable.com/open-source/nuka-carousel/api/#default-controls-configuration
    defaultControlsConfig: {
      // nextButtonText: '>',
      // prevButtonText: '<',
      prevButtonStyle: {
        opacity: '0',
      },
      nextButtonStyle: {
        opacity: '0',
      },
      pagingDotsStyle: {
        opacity: '0',
      },
    },
    disableAnimation: true, // tắt chuyển động slide
  }
  return (
    <Carousel {...settings}>
      <Link to="/menu/">
        <StaticImage
          src={'../../assets/images/slider-images/main-23-02.jpg'}
          alt="Menu món ăn/uống"
        ></StaticImage>
      </Link>
    </Carousel>
  )
}

export default MainSlider
